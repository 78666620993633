<template>
  <div>
    <v-container>
      <v-row class="pt-16 pb-16">
        <v-col>
          <h2>Empresa</h2>
          <p>
            Somos um grupo 100% brasileiro, criado em 2003, com sede em Brasília-DF. Desenvolvemos produtos e prestamos serviços de alta qualidade utilizando tecnologia de ponta a partir da concepção de soluções versáteis para atender as demandas dos nossos clientes.   Somos a solução completa para atender as necessidades em: ·Gestão de Projetos ·Segurança e vigilância eletrônica ·Infraestrutura ·Desenvolvimento de software   (NETTO, confirmar o texto, por favor)
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-card dark flat tile :color="constants.COLOR_PRIMARY">
      <v-container>
        <v-row class="pt-16 pb-16">
          <v-col>
            <h2>Missão</h2>
            <p>
              Oferecer solução completa para nossos clientes, desde a implantação até a manutenção dos produtos que atendam os anseios de nossos clientes e os ajudem no desenvolvimento de seus negócios
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container>
      <v-row class="pt-16 pb-16">
        <v-col>
          <h2>Visão</h2>
          <p>
            Ser uma empresa de referência no mercado de Tecnologia da Informação; Sinônimo de inovação, desenvolvimento e qualidade.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-card dark flat tile :color="constants.COLOR_PRIMARY">
      <v-container>
        <v-row class="pt-16 pb-16">
          <v-col>
            <h2>Valores</h2>
            <p>
              Foco no cliente.  •Eficácia e rapidez •Qualidade •Flexibilidade e transparência. •Qualificação contínua dos profissionais.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="9" lg="7">
          <div class="text-center">
            <h2>
              Experienced & Professional Team
            </h2>
            <p>
              You can relay on our amazing features list and also our customer
              services will be great experience for you without doubt and in
              no-time
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-13">
        <v-col cols="12" md="6" lg="3" v-for="item in 4" :key="item">
          <v-card elevation="0" class="team-card overflow-hidden mb-15">
            <div class="social-overlay">
              <v-img
                :src="require('@/assets/img/pessoa.jpg')"
                alt="team"
              />
            </div>
            <div class="mt-3">
              <h3 class="title">Michael Doe</h3>
              <p class="text--disabled">Property Specialist</p>
            </div>
            <p class="text--disabled">
              You can relay on our amazing features list and also our customer
              services will be great experience.
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Constants from "@/constants/constants";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      "stateLogin",
    ]),
  },
  data() {
    return {
      constants: Constants,
      vueAppApiResources: process.env.VUE_APP_API_RESOURCES,
    };
  },
  methods: {},
};
</script>
